import React from "react";

const ResumeView = () => {
  return (
    <div style={{ height: "100%" }}>
      <embed src="/files/WilfredBayudan.pdf" width="100%" height="100%" />
    </div>
  );
};

export default ResumeView;
